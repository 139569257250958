"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dexie_1 = require("dexie");
class MojidonBoosterDatabase extends dexie_1.default {
    constructor() {
        super("MojidonBoosterDatabase");
        this.version(2).stores({
            mastodonAccounts: "++acct, accessToken",
            mojiAccounts: "++code, url",
        });
        this.version(1).stores({
            mastodonAccounts: "++acct, accessToken",
        });
    }
}
exports.database = new MojidonBoosterDatabase();
