"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const database_1 = require("../database");
const axios_1 = require("axios");
const modal_vue_1 = require("./common/modal.vue");
const oobUrl = "urn:ietf:wg:oauth:2.0:oob";
let App = class App extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.text = "ア";
        this.accounts = [];
        this.mastodonAccounts = [];
        this.dialogState = "none";
        this.loginInstance = "";
        this.loginInstanceInfo = undefined;
        this.oauthCode = "";
        this.nowSelectAccount = null;
        this.stateMax = 0;
        this.stateNow = 0;
    }
    async mounted() {
        await this.fetchAccountsFromDatabase();
    }
    async fetchAccountsFromDatabase() {
        this.mastodonAccounts = await database_1.database.mastodonAccounts.toArray();
        if (this.nowSelectAccount == null && this.mastodonAccounts.length) {
            this.nowSelectAccount = this.mastodonAccounts[0].acct;
        }
    }
    async fetchMojiAccount() {
        const string = this.text;
        this.accounts = new Array(string.length).fill(null);
        var i = 0;
        for (const char of string) {
            const charCode = char.charCodeAt(0).toString(16);
            console.log(charCode);
            try {
                const cached = await database_1.database.mojiAccounts.get(charCode);
                if (cached)
                    throw cached.url;
                const res = await fetch("https://moji.m.to/@" + charCode + ".atom");
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
                const parser = new DOMParser();
                const dom = await parser.parseFromString(await res.text(), "application/xml");
                const url = dom.querySelector(`feed > entry > link[rel=alternate][type="text/html"]`).getAttribute("href");
                if (url == null)
                    throw new Error("url is null");
                database_1.database.mojiAccounts.put({
                    code: charCode,
                    url,
                });
                throw url;
            }
            catch (e) {
                vue_property_decorator_1.Vue.set(this.accounts, i, e);
            }
            i++;
        }
    }
    async openAuthWindow() {
        const host = this.loginInstance;
        if (host === "")
            return;
        var dialog = window.open("about:blank", "_blank", "width=480,height=560");
        if (dialog == null)
            return alert("ダイアログが開けなくてかなしい");
        dialog.document.body.innerHTML = "ちょっとまってね";
        try {
            const res = await axios_1.default.post("https://" + host + "/api/v1/apps", {
                client_name: "Mojidon Booster",
                redirect_uris: oobUrl,
                scopes: "read write",
                website: location.origin,
            });
            if (res.status >= 400) {
                dialog.close();
                throw new Error("エラー: " + res.statusText);
            }
            const url = "https://" + host + "/oauth/authorize?client_id=" + res.data.client_id + "&scope=read+write&redirect_uri=urn:ietf:wg:oauth:2.0:oob&response_type=code";
            dialog.location.href = url;
            this.loginInstanceInfo = {
                clientId: res.data.client_id,
                clientSecret: res.data.client_secret,
            };
            this.dialogState = "authInputCode";
        }
        catch (e) {
            dialog.close();
            alert(e.toString());
        }
    }
    async authWithCode() {
        const host = this.loginInstance;
        const res = await axios_1.default.post("https://" + host + "/oauth/token", {
            grant_type: "authorization_code",
            client_id: this.loginInstanceInfo.clientId,
            client_secret: this.loginInstanceInfo.clientSecret,
            redirect_uri: oobUrl,
            code: this.oauthCode,
        });
        if (res.data.error)
            return alert(res.data.error);
        const token = res.data.access_token;
        const userInfo = await axios_1.default.get("https://" + host + "/api/v1/accounts/verify_credentials", {
            headers: { Authorization: "Bearer " + token }
        });
        if (userInfo.data.error)
            return alert(res.data.error);
        const acct = [userInfo.data.username, host.toLowerCase()].join("@");
        database_1.database.mastodonAccounts.put({
            acct,
            accessToken: token
        });
        await this.fetchAccountsFromDatabase();
        this.dialogState = "none";
    }
    async boost() {
        function sleep(msec) {
            return new Promise(r => setTimeout(r, msec));
        }
        const account = this.mastodonAccounts.find(a => a.acct === this.nowSelectAccount);
        if (account == null)
            return alert("アカウントを追加してください");
        const token = account.accessToken;
        const requestor = axios_1.default.create({
            baseURL: "https://" + account.acct.split("@")[1],
            headers: {
                Authorization: "Bearer " + token,
            }
        });
        this.stateMax = this.accounts.length * 2;
        this.stateNow = 0;
        var localIds = [];
        for (const tootUrl of this.accounts) {
            if (typeof tootUrl === "string") {
                const res = await requestor.get("/api/v1/search?q=" + encodeURIComponent(tootUrl));
                if (res.data.statuses.length === 0)
                    return alert(tootUrl + "がみつかりませんでした");
                const post = res.data.statuses[0];
                if (post.reblogged)
                    await requestor.post("/api/v1/statuses/" + post.id + "/unreblog");
                localIds.push(post.id);
                await sleep(1000);
                this.stateNow++;
            }
        }
        for (const id of localIds.reverse()) {
            await requestor.post("/api/v1/statuses/" + id + "/reblog");
            await sleep(1000);
            this.stateNow++;
        }
    }
};
App = __decorate([
    vue_property_decorator_1.Component({
        components: { Modal: modal_vue_1.default },
    })
], App);
exports.default = App;
