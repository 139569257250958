var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", [
        _c("div", { staticClass: "modal-mask", on: { click: _vm.close } }, [
          _c(
            "div",
            { staticClass: "modal-wrapper", on: { click: _vm.eventCanceller } },
            [
              _c(
                "div",
                { staticClass: "modal-content" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "modal-close-button",
                      on: { click: _vm.close }
                    },
                    [_vm._v("×")]
                  ),
                  _vm._v(" "),
                  _vm._t("content")
                ],
                2
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }