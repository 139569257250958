"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const app_vue_1 = require("./components/app.vue");
const root = document.createElement("div");
root.id = "root";
document.body.appendChild(root);
const app = new vue_1.default({
    el: root,
    render: (h) => h(app_vue_1.default),
});
